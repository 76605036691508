#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}
.site-layout{
  text-align: right;
}
.sideMenu {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 10000 !important;
  left: -100%;
  transition: 0.3s;
  
}
.ant-layout-sider.ant-layout-sider-dark{
  height: 100%;
}
h2{
  position: absolute;
}
.rdrDefinedRangesWrapper {
  display: none;
}
.dateSelectorfixed {
  background: #fff;
  border-radius: 20px;
  padding: 5px 20px;
  font-size: 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 10px lightgrey;
}
.dateSelectorfixed p {
  margin-bottom: 0px;
}
.dateSelectorfixed .compare{
  font-size: 10.5px;
    text-align: left;
    z-index: 10000;
}

.dateSelectorfixed .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}
.dateSelectorfixed > * {
  margin-left: 5px;
  margin-right: 5px;
}
